import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { supabase } from "../configurations/supabaseConfig";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// JSON data for form structure
const formData = {
  formTitle: "Know Your Path Webinar Series",
  fields: [
    { type: "text", label: "Full Name", required: true },
    { type: "text", label: "Phone Number", required: true },
    { type: "text", label: "WhatsApp Number", required: true },
    { type: "text", label: "Email Address", required: true },
    {
      type: "dropdown",
      label: "Your Current Status",
      options: [
        "School Student",
        "O/L Completed",
        "A/L Completed",
        "University Student",
        "Working",
      ],
      required: true,
    },
    { type: "text", label: "Name of your School/University/Working place" },
    {
      type: "dropdown",
      label: "Gender",
      options: ["Male", "Female", "Other"],
      required: true,
    },
    {
      type: "dropdown",
      label: "Your District",
      options: [
        "Jaffna",
        "Kilinochchi",
        "Mannar",
        "Mullaitivu",
        "Vavuniya",
        "Puttalam",
        "Kurunegala",
        "Gampaha",
        "Colombo",
        "Kalutara",
        "Anuradhapura",
        "Polonnaruwa",
        "Matale",
        "Kandy",
        "Nuwara Eliya",
        "Kegalle",
        "Ratnapura",
        "Trincomalee",
        "Batticaloa",
        "Ampara",
        "Badulla",
        "Monaragala",
        "Hambantota",
        "Matara",
        "Galle",
        "Other",
      ],
      required: true,
    },
    {
      type: "checkbox",
      label: "Select the Webinars You’d Like to Attend",
      options: [
        "React Native",
        "React",
        "Cloud Computing",
        "UI/UX Design",
        "Python",
        "Django (BackEnd)",
      ],
      required: true,
    },
    {
      type: "dropdown",
      label: "Are you interested in a full course on any topics?",
      options: [
        "Yes, I’m interested!",
        "Maybe, need more info.",
        "No, just the webinars for now.",
      ],
      required: true,
    },
    {
      type: "dropdown",
      label: "How did you learn about this Webinar?",
      options: ["Facebook", "Instagram", "WhatsApp", "From friend", "Other"],
      required: true,
    },
    { type: "text", label: "Additional Questions or Comments" },
  ],
};

const KnowYourPath = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValues = formData.fields.reduce((acc, field) => {
    acc[field.label] = field.type === "checkbox" ? [] : "";
    return acc;
  }, {});

  const validationSchema = Yup.object(
    formData.fields.reduce((acc, field) => {
      if (field.required) {
        acc[field.label] =
          field.type === "text"
            ? Yup.string().required("Required")
            : field.type === "dropdown"
            ? Yup.string().required("Required")
            : field.type === "checkbox"
            ? Yup.array().min(1, "Select at least one option")
            : null;
      }
      return acc;
    }, {})
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { error } = await supabase
          .from("details")
          .insert([{ title: formData.formTitle, form_data: values }]);

        if (error) throw error;
        setIsSubmitted(true);
        toast.success("Form submitted successfully!");
        formik.resetForm();
      } catch (error) {
        toast.error("Error submitting form.");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <ToastContainer />
      <Box width={{ xs: "90%", md: "60%" }}>
        {!isSubmitted ? (
          <form onSubmit={formik.handleSubmit}>
            <Card sx={{ p: 3, mb: 3, borderRadius: "10px" }}>
              <Typography variant="h5" gutterBottom>
                {formData.formTitle}
              </Typography>
              {formData.fields.map((field, index) => {
                if (field.type === "text") {
                  return (
                    <Card key={index} sx={{ p: 2, mb: 2, boxShadow: 0 }}>
                      <Typography variant="h6">{field.label}</Typography>
                      <TextField
                        fullWidth
                        name={field.label}
                        variant="standard"
                        value={formik.values[field.label]}
                        onChange={formik.handleChange}
                        error={
                          formik.touched[field.label] &&
                          Boolean(formik.errors[field.label])
                        }
                        helperText={
                          formik.touched[field.label] &&
                          formik.errors[field.label]
                        }
                      />
                    </Card>
                  );
                } else if (field.type === "dropdown") {
                  return (
                    <Card key={index} sx={{ p: 2, mb: 2, boxShadow: 0 }}>
                      <Typography variant="h6">{field.label}</Typography>
                      <FormControl fullWidth>
                        <InputLabel>{field.label}</InputLabel>
                        <Select
                          name={field.label}
                          value={formik.values[field.label]}
                          onChange={formik.handleChange}
                          error={
                            formik.touched[field.label] &&
                            Boolean(formik.errors[field.label])
                          }
                        >
                          {field.options.map((option, idx) => (
                            <MenuItem key={idx} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Card>
                  );
                } else if (field.type === "checkbox") {
                  return (
                    <Card key={index} sx={{ p: 2, mb: 2, boxShadow: 0 }}>
                      <Typography variant="h6">{field.label}</Typography>
                      {field.options.map((option, idx) => (
                        <FormControlLabel
                          key={idx}
                          sx={{ display: "block" }}
                          control={
                            <Checkbox
                              checked={formik.values[field.label].includes(
                                option
                              )}
                              onChange={(e) => {
                                const newValue = formik.values[
                                  field.label
                                ].includes(option)
                                  ? formik.values[field.label].filter(
                                      (val) => val !== option
                                    )
                                  : [...formik.values[field.label], option];
                                formik.setFieldValue(field.label, newValue);
                              }}
                            />
                          }
                          label={option}
                        />
                      ))}
                    </Card>
                  );
                }
                return null;
              })}

              <Button
                variant="contained"
                type="submit"
                fullWidth
                disabled={loading}
                endIcon={loading && <CircularProgress size={20} />}
              >
                {loading ? "Submitting..." : "Submit"}
              </Button>
            </Card>
          </form>
        ) : (
          <Paper elevation={3} sx={{ p: 3, textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
              🎉 Thank you for registering!
            </Typography>
            <Typography variant="body1" paragraph>
              Know Your Path - Webinar Series தொடர்பான விடயங்கள் மற்றும் இந்த
              Webinar Series இல் இணைந்து கொள்வதற்கான Google meet Link இனை
              பெற்றுக்கொள்வதற்கும் கீழே உள்ள WhatsApp Group invite Link இனை
              கிளிக் செய்து TrendsED இன் Official WhatsApp Group இல் இணைந்து
              கொள்ளுங்கள்.
              <br />
              Follow this link to join TrendsED - Know Your Path WhatsApp
              Community :{" "}
              <a href="https://chat.whatsapp.com/HanyBaTXlwR9nYZfZfjpON">
                https://chat.whatsapp.com/HanyBaTXlwR9nYZfZfjpON
              </a>
            </Typography>
            <Button variant="outlined" onClick={() => setIsSubmitted(false)}>
              Submit another response
            </Button>
          </Paper>
        )}
      </Box>
    </Box>
  );
};

export default KnowYourPath;
