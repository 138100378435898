import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AccessDeniedPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f8f9fa"
    >
      <Typography variant="h1" color="primary" gutterBottom>
        🚫
      </Typography>
      <Typography variant="h4" gutterBottom>
        Access Denied
      </Typography>
      <Typography
        variant="body1"
        color="textSecondary"
        align="center"
        sx={{ mb: 4 }}
      >
        You don’t have access to this page.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoBack}>
        Go Back
      </Button>
    </Box>
  );
};

export default AccessDeniedPage;
