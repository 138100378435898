import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import WhatsAppButton from "./component/whatsAppButton";
import KnowYourPath from "./pages/knowYourPath";
import ResultKnowYourPath from "./pages/resultKnowYourPath";
import AccessDeniedPage from "./pages/accessDenied";

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<RegistrationForm />} /> */}
        <Route path="/know-your-path" element={<KnowYourPath />} />
        <Route path="/result-kyp" element={<ResultKnowYourPath />} />
        <Route path="*" element={<AccessDeniedPage />} />
      </Routes>
      <WhatsAppButton />
    </Router>
  );
}

export default App;
