import React, { useState, useEffect } from "react";
import { supabase } from "../configurations/supabaseConfig";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { CSVLink } from "react-csv";

// JSON structure for table headers
const formData = {
  formTitle: "Know Your Path Webinar Series",
  fields: [
    { type: "text", label: "Full Name" },
    { type: "text", label: "Phone Number" },
    { type: "text", label: "WhatsApp Number" },
    { type: "text", label: "Email Address" },
    { type: "dropdown", label: "Your Current Status" },
    { type: "text", label: "Name of your School/University/Working place" },
    { type: "dropdown", label: "Gender" },
    { type: "dropdown", label: "Your District" },
    { type: "checkbox", label: "Select the Webinars You’d Like to Attend" },
    {
      type: "dropdown",
      label: "Are you interested in a full course on any topics?",
    },
    { type: "dropdown", label: "How did you learn about this Webinar?" },
    { type: "text", label: "Additional Questions or Comments" },
  ],
};

const ResultKnowYourPath = () => {
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRegistrations = async () => {
      const { data, error } = await supabase.from("details").select("*");
      if (error) console.error("Error fetching registrations:", error);
      else setRegistrations(data);
      setLoading(false);
    };
    fetchRegistrations();
  }, []);

  const headers = [
    { label: "Title", key: "title" },
    ...formData.fields.map((field) => ({
      label: field.label,
      key: `form_data.${field.label}`,
    })),
  ];

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        {formData.formTitle} - Registrations
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <CSVLink
            data={registrations}
            headers={headers}
            filename="registrations.csv"
          >
            <Button variant="contained" color="primary" sx={{ mb: 3 }}>
              Download CSV
            </Button>
          </CSVLink>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {formData.fields.map((field, idx) => (
                    <TableCell key={idx}>
                      <strong>{field.label}</strong>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {registrations.map((registration) => (
                  <TableRow key={registration.id}>
                    {formData.fields.map((field, idx) => (
                      <TableCell key={idx}>
                        {Array.isArray(registration.form_data[field.label])
                          ? registration.form_data[field.label].join(", ")
                          : registration.form_data[field.label] || "N/A"}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default ResultKnowYourPath;
